import React, { useState, useEffect } from 'react';
import './GalleryUpdate.css';
import { storage, db } from '../../firebaseConfig'; // Ensure these are correctly imported
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, getDocs } from 'firebase/firestore';

function GalleryUpdate({ onImageUpload }) {
    const [images, setImages] = useState([]);
    const [file, setFile] = useState(null);
    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        // Fetch categories from a constant or database if needed
        setCategories([
            'HOTEL', 'SWIMMING POOL', 'ANURADAPURA', 'POLONNARUWA',
            'FACILITIES', 'SIGIRIYA', 'WATERFALLS', 'SRI LANKA BEACH',
            'KANDY', 'DECEMBER 31ST NIGHT', 'HILL COUNTRY', 'CHRISTMAS',
            'AYURVEDA TREATMENT CENTER', 'TOURS', 'HISTORICAL TOUR',
            'NATURE TOUR'
        ]);

        // Fetch existing images from Firestore
        const fetchImages = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "galleryImages"));
                const imageList = querySnapshot.docs.map(doc => doc.data());
                setImages(imageList);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchImages();
    }, []);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
    };

    const handleUpload = async () => {
        if (!file || !category) {
            alert("Please select a file and a category.");
            return;
        }

        // Create a reference to the storage location
        const storageRef = ref(storage, `gallery/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed',
            (snapshot) => {
                // Handle upload progress if necessary
            },
            (error) => {
                console.error('Upload error:', error);
                alert('An error occurred while uploading the image.');
            },
            () => {
                // Get the download URL of the uploaded file
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                    const newImage = { src: downloadURL, category };

                    // Save image metadata to Firestore
                    try {
                        await addDoc(collection(db, "galleryImages"), newImage);
                        setImages([...images, newImage]);

                        // Call the callback function to update the gallery
                        if (onImageUpload) {
                            onImageUpload(newImage);
                        }

                        // Reset the file input and category
                        setFile(null);
                        setCategory('');
                    } catch (error) {
                        console.error('Error adding document to Firestore:', error);
                    }
                });
            }
        );
    };

    return (
        <div className="gallery-update">
            <h1>Gallery Update</h1>
            <div className="image-upload-section">
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                />
                <select value={category} onChange={handleCategoryChange}>
                    <option value="">Select Category</option>
                    {categories.map((cat, index) => (
                        <option key={index} value={cat}>{cat}</option>
                    ))}
                </select>
                <button onClick={handleUpload}>Upload Image</button>
            </div>

            <div className="existing-images">
                <h2>Existing Images</h2>
                <div className="existing-images-grid">
                    {images.map((image, index) => (
                        <div key={index} className="existing-image-item">
                            <img src={image.src} alt={image.category} />
                            <p>{image.category}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default GalleryUpdate;
