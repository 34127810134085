import React from 'react';
import './ProgressBar.css';
import Navbar from '../../components/Navbar';

function ProgressBar({ currentStep }) {
    return (
        <div>
            <Navbar />
            <div className="progress-bar">
                <div className={`progress-step ${currentStep === 'search' ? 'active' : currentStep !== 'search' ? 'completed' : ''}`}>
                    <span>1</span>
                    <p>Search</p>
                    <small>Choose your favorite room</small>
                </div>
                <div className={`progress-step ${currentStep === 'booking' ? 'active' : currentStep === 'checkout' || currentStep === 'confirmation' ? 'completed' : ''}`}>
                    <span>2</span>
                    <p>Booking</p>
                    <small>Enter your booking details</small>
                </div>
                <div className={`progress-step ${currentStep === 'checkout' ? 'active' : currentStep === 'confirmation' ? 'completed' : ''}`}>
                    <span>3</span>
                    <p>Checkout</p>
                    <small>Use your preferred payment method</small>
                </div>
                <div className={`progress-step ${currentStep === 'confirmation' ? 'active' : ''}`}>
                    <span>4</span>
                    <p>Confirmation</p>
                    <small>Receive a confirmation email</small>
                </div>
            </div>
        </div>
    );
}

export default ProgressBar;
