// src/pages/Services.js

import React from 'react';
import './Services.css';
import { FaWifi, FaTv, FaSpa, FaUtensils, FaTaxi, FaGlassMartiniAlt, FaFan, FaMusic, FaHotTub } from 'react-icons/fa';
import { MdOutlineBalcony, MdOutlineAir } from 'react-icons/md';
import { AiOutlineCoffee } from 'react-icons/ai';
import { PiHairDryerFill } from "react-icons/pi";

function Services() {
    return (
        <div className="services">
            <h1>Our Services</h1>
            <p>Discover the services we offer to enhance your stay.</p>
            <div className="services-box">
                <div className="services-column">
                    <p><FaTaxi /> Airport Pickup</p>
                    <p><MdOutlineAir /> Air Conditioner</p>
                    <p><AiOutlineCoffee /> Tea/Coffee Making facilities (Tea Tray)</p>
                    <p><FaGlassMartiniAlt /> Mini Bar</p>
                    <p><FaMusic /> Piped Music</p>
                    <p><FaFan /> Ceiling Fan</p>
                    <p><FaHotTub /> Hot Water</p>
                </div>
                <div className="services-column">
                    <p><FaWifi /> Wi-Fi</p>
                    <p><FaTv /> Widescreen TV</p>
                    <p><PiHairDryerFill /> Hair Dryer</p>
                    <p>📱 Free-to-use Smartphone</p> {/* Use a simple phone emoji as a substitute */}
                    <p>Cable Television</p>
                    <p>Attached Bath</p>
                    <p>Sofa set for 6 persons</p>
                </div>
                <div className="services-column">
                    <p><MdOutlineBalcony /> Private Balcony</p>
                    <p><FaSpa /> Massage & Spa</p>
                    <p><FaUtensils /> Breakfast</p>
                    <p>En-suite Bathroom with Hot & Cold Water</p>
                    <p>Split Unit Air Conditioner</p>
                    <p>6 Chairs</p>
                </div>
            </div>
        </div>
    );
}

export default Services;
