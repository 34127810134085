import { initializeApp, getApps } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Firebase configuration for your project
const firebaseConfig = {
    apiKey: "AIzaSyCR7QXIFCN45lPGkUDqVv4hVNnL6NTNi04",
    authDomain: "reviews-submission.firebaseapp.com",
    projectId: "reviews-submission",
    storageBucket: "reviews-submission.appspot.com",
    messagingSenderId: "1084042816836",
    appId: "1:1084042816836:web:a409329882a3631740fb11",
    measurementId: "G-K5G42GTDF8"
};

// Initialize Firebase only if no apps have been initialized yet
let app;
if (!getApps().length) {
    app = initializeApp(firebaseConfig);
} else {
    app = getApps()[0]; // Use the already initialized app
}

// Initialize Firestore
const db = getFirestore(app);

export { db };
