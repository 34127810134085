import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Icons from 'react-icons/fa';
import './Booking.css';
import ProgressBar from './ProgressBar';
import Footer from '../../components/Footer';
import { db } from '../../firebaseSetup'; // Import Firestore from firebaseSetup.js
import { collection, addDoc, Timestamp } from 'firebase/firestore'; // Firestore functions

function Booking() {
    const location = useLocation();
    const navigate = useNavigate();

    const [bookingDetails, setBookingDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        country: '',
        zip: '',
        requests: '',
        arrival: 'I do not know',
        coupon: ''
    });

    // Check if location state contains room information
    if (!location.state || !location.state.room) {
        console.error("No room information found in location state");
        navigate('/');
        return null;
    }

    const { room, adults, children, childrenAges, rooms } = location.state; // Extract childrenAges

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBookingDetails(prevState => ({ ...prevState, [name]: value }));
    };

    const handleBooking = async (e) => {
        e.preventDefault();

        try {
            // Format the dates correctly
            const checkInDate = Timestamp.fromDate(new Date(room.checkIn));
            const checkOutDate = Timestamp.fromDate(new Date(room.checkOut));

            // Save booking details to Firestore
            await addDoc(collection(db, "bookings"), {
                room_name: room.name,
                check_in: checkInDate,
                check_out: checkOutDate,
                total_price: parseFloat(room.totalPrice),
                adults: adults, // Saving number of adults
                children: children, // Saving number of children
                childrenAges: childrenAges || [], // Ensure it's an array (even if empty)
                rooms: rooms, // Saving number of rooms
                first_name: bookingDetails.firstName,
                last_name: bookingDetails.lastName,
                email: bookingDetails.email,
                phone: bookingDetails.phone,
                address: bookingDetails.address,
                city: bookingDetails.city,
                country: bookingDetails.country,
                zip: bookingDetails.zip,
                requests: bookingDetails.requests,
                arrival: bookingDetails.arrival,
                coupon: bookingDetails.coupon
            });

            console.log("Booking details saved successfully");

            // Navigate to the Checkout page with booking details
            navigate('/booking/checkout', {
                state: {
                    room,
                    adults,
                    children,
                    childrenAges, // Pass children's ages to checkout
                    rooms, // Pass number of rooms to checkout
                    bookingDetails
                }
            });
        } catch (error) {
            console.error("Error adding booking to Firestore:", error);
        }
    };

    const ServiceIcon = ({ iconName }) => {
        const IconComponent = Icons[iconName];
        return IconComponent ? <IconComponent /> : null;
    };

    return (
        <div>
            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            <ProgressBar currentStep="booking" />
            <div className="custom-booking-page-container">
                <div className="custom-booking-summary">
                    <img src={room.image} alt={room.name} className="custom-room-image" />
                    <div className="custom-booking-summary-details">
                        <h2>Selected Room: {room.name}</h2>
                        <p>Check In: {new Date(room.checkIn).toLocaleDateString()}</p>
                        <p>Check Out: {new Date(room.checkOut).toLocaleDateString()}</p>
                        <p>Nights: {room.nights}</p>
                        <p>Adults: {adults}</p> {/* Display number of adults */}
                        <p>Children: {children}</p> {/* Display number of children */}
                        <p>
                            Children Ages: {childrenAges && childrenAges.length > 0
                                ? childrenAges.map(age => `${age} ${age === 1 ? 'year old' : 'years old'}`).join(', ')
                                : 'No children'}
                        </p> {/* Display children's ages like "2 years old" */}
                        <p>Rooms: {rooms}</p> {/* Display number of rooms */}
                        <p>Total Price: ${parseFloat(room.totalPrice).toFixed(2)} for {rooms} room(s)</p> {/* Updated total price logic */}
                        <p>10% SERVICE CHARGE ALREADY APPLIED</p>
                        <div className="custom-services-container">
                            {room.services.map((service, index) => (
                                <div className="custom-service-item" key={index}>
                                    <ServiceIcon iconName={service.icon} />
                                    <span>{service.text}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="custom-form-container">
                    <form className="custom-booking-form" onSubmit={handleBooking}>
                        <h1>Booking Details</h1>
                        <label className="custom-label">Name:
                            <input type="text" name="firstName" value={bookingDetails.firstName} onChange={handleChange} required placeholder="First Name" className="custom-input" />
                            <input type="text" name="lastName" value={bookingDetails.lastName} onChange={handleChange} required placeholder="Surname" className="custom-input" />
                        </label>
                        <label className="custom-label">Email:
                            <input type="email" name="email" value={bookingDetails.email} onChange={handleChange} required placeholder="Email" className="custom-input" />
                        </label>
                        <label className="custom-label">Phone:
                            <input type="text" name="phone" value={bookingDetails.phone} onChange={handleChange} required placeholder="Phone" className="custom-input" />
                        </label>
                        <label className="custom-label">Address:
                            <input type="text" name="address" value={bookingDetails.address} onChange={handleChange} placeholder="Address" className="custom-input" />
                            <input type="text" name="city" value={bookingDetails.city} onChange={handleChange} placeholder="City" className="custom-input" />
                            <input type="text" name="country" value={bookingDetails.country} onChange={handleChange} placeholder="Country" className="custom-input" />
                            <input type="text" name="zip" value={bookingDetails.zip} onChange={handleChange} placeholder="ZIP" className="custom-input" />
                        </label>
                        <label className="custom-label">Requests:
                            <textarea name="requests" value={bookingDetails.requests} onChange={handleChange} placeholder="Any special requests?" className="custom-textarea" />
                        </label>
                        <label className="custom-label">Arrival:
                            <select name="arrival" value={bookingDetails.arrival} onChange={handleChange} className="custom-select">
                                <option value="I do not know">I do not know</option>
                                <option value="Morning">Morning</option>
                                <option value="Afternoon">Afternoon</option>
                                <option value="Evening">Evening</option>
                            </select>
                        </label>
                        <label className="custom-terms-label">
                            <input type="checkbox" name="terms" required /> I agree to the <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
                        </label>
                        <button type="submit" className="custom-checkout-button">Proceed to Checkout</button>
                    </form>

                    <button className="custom-go-back-button" onClick={() => navigate(-1)}>Go Back</button>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Booking;
