import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../components/AuthContext'; // Ensure this path is correct
import './AdminDashboard.css';
import hotelLogo from '../../assets/Navbar/Bougainvilla.png'; // Make sure this path is correct
import { db } from '../../firebaseSetup'; // Import Firestore from firebaseSetup.js
import { collection, getDocs } from 'firebase/firestore';

function AdminDashboard() {
    const { logout } = useAuth(); // Access the logout function from AuthContext
    const [latestBookings, setLatestBookings] = useState([]);

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "bookings"));
                const bookings = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    // Convert Firestore Timestamp to JavaScript Date
                    return {
                        ...data,
                        check_in: data.check_in.toDate(),
                        check_out: data.check_out.toDate(),
                        total_price: parseFloat(data.total_price)
                    };
                });
                console.log("Fetched bookings:", bookings); // Log fetched bookings
                setLatestBookings(bookings);
            } catch (error) {
                console.error('Error fetching bookings:', error);
            }
        };

        fetchBookings();
    }, []);

    return (
        <div className="admin-dashboard">
            <aside className="sidebar">
                <div className="logo">
                    <img src={hotelLogo} alt="Hotel Bougainvilla" className="hotel-logo" />
                    <h2>BOUGAINVILLA</h2>
                </div>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/rooms/rooms-list">Rooms</Link></li>
                    <li><Link to="/booking">Bookings</Link></li>
                    <li><Link to="/">Website</Link></li>
                    <li><Link to="/admin/login">Admin Users</Link></li>
                    <li><Link to="/admin/gallery-update">Gallery Update</Link></li>
                    <li><Link to="/admin/review-update">Reviews Update</Link></li>
                    <li><button onClick={logout} className="logout-button">Logout</button></li>
                </ul>
            </aside>
            <main className="main-content">
                <header className="admin-header">
                    <h1>Dashboard</h1>
                    <div className="admin-header-right">
                        <a href="/" className="admin-open-site">Open The Main Site</a>
                        <span className="admin-user">Administrator</span>
                    </div>
                </header>
                <section className="admin-overview">
                    <div className="overview-item">
                        <h2>27</h2>
                        <p>Room Types</p>
                    </div>
                </section>
                <section className="latest-bookings">
                    <h2>Latest Bookings</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Room</th>
                                <th>Check In</th>
                                <th>Check Out</th>
                                <th>Total</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                            </tr>
                        </thead>
                        <tbody>
                            {latestBookings.map((booking, index) => (
                                <tr key={index}>
                                    <td>{booking.room_name}</td>
                                    <td>{new Date(booking.check_in).toLocaleDateString()}</td>
                                    <td>{new Date(booking.check_out).toLocaleDateString()}</td>
                                    <td>${booking.total_price.toFixed(2)}</td>
                                    <td>{booking.first_name}</td>
                                    <td>{booking.last_name}</td>
                                    <td>{booking.email}</td>
                                    <td>{booking.phone}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
            </main>
        </div>
    );
}

export default AdminDashboard;
