import React from 'react';
import './Footer.css';
import { FaPhoneAlt, FaEnvelope, FaInstagram, FaFacebookF, FaTwitter, FaYoutube, FaWhatsapp, FaMapMarkerAlt } from 'react-icons/fa';
import backgroundImage from '../assets/Footer/DSC_6678.JPG'; // Adjust the path according to your project structure

function Footer() {
    return (
        <footer className="footer" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <div className="footer-overlay">
                <div className="footer-top">
                    <div className="footer-links">
                        <a href="/">HOME</a>
                        <a href="/about-us">ABOUT US</a>
                        <a href="/rooms">ROOMS</a>
                        <a href="/gallery">GALLERY</a>
                        <a href="/reviews">REVIEWS</a>
                        <a href="/contact-us">CONTACT US</a>

                    </div>
                </div>
                <div className="footer-middle">
                    <div className="footer-info">
                        <p className="hotel-name">BOUGAINVILLA</p>
                        <p className="hotel-address">
                            <FaMapMarkerAlt /> Hotel Bougainvilla, Sripathi Road, Kaluwamodara, Aluthgama, Sri Lanka
                        </p>
                    </div>
                    <div className="footer-contact">
                        <p><FaPhoneAlt /> +94 77 2358914/ +94 342271925</p>
                        <p><FaEnvelope /> Bougainvillasrilanka@yahoo.com</p>
                        <p><FaEnvelope /> info@bougainvilla.lk</p>
                    </div>
                    <div className="footer-social">
                        <p>Subscribe</p>
                        <div className="subscribe-section">
                            <input type="email" placeholder="Enter Your Email" />
                            <button className="subscribe-button">→</button>
                        </div>
                        <div className="social-icons">
                            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                                <FaInstagram />
                            </a>
                            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                <FaFacebookF />
                            </a>
                            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                                <FaTwitter />
                            </a>
                            <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                                <FaYoutube />
                            </a>
                            <a href="https://wa.me/4915781519404" target="_blank" rel="noopener noreferrer">
                                <FaWhatsapp />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">

                    <p>&copy; 2024 Hotel Bougainvilla. All rights reserved.</p>
                    <p>
                        <a href="https://wa.me/94764608225" target="_blank" rel="noopener noreferrer">
                            Developed By Ricky Fernando
                        </a>
                    </p>
                    <div className="footer-bottom-links">
                        <a href="/terms-and-conditions">Terms & Conditions</a>
                        <a href="/privacy-policy">Privacy Policy</a>
                        <a href="/sitemap">Sitemap</a>
                    </div>

                </div>
            </div>
        </footer>
    );
}

export default Footer;