import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminRegister.css';
import hotelLogo from '../../assets/Navbar/Bougainvilla.png';
import { auth } from '../../firebaseConfig'; // Import Firebase auth
import { createUserWithEmailAndPassword } from "firebase/auth"; // Import Firebase auth method

function AdminRegister() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            alert('Passwords do not match!');
            return;
        }

        try {
            // Register the admin with Firebase Authentication
            await createUserWithEmailAndPassword(auth, email, password);
            alert('Admin registered successfully!');
            navigate('/admin/login');
        } catch (error) {
            alert('An error occurred during registration: ' + error.message);
        }
    };

    return (
        <div className="admin-register-container">
            <div className="admin-register-left">
                <img src={hotelLogo} alt="Hotel Logo" />
                <h2>HOTEL BOUGAINVILLA</h2>
            </div>
            <div className="admin-register-right">
                <div className="admin-register-box">
                    <h1 className="admin-register-title">Admin Register</h1>
                    <form className="admin-register-form" onSubmit={handleRegister}>
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="admin-register-input"
                            required
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="admin-register-input"
                            required
                        />
                        <input
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="admin-register-input"
                            required
                        />
                        <button type="submit" className="admin-register-button">Register</button>
                    </form>
                    <div className="admin-register-link">
                        <p>Already have an account? <a href="/admin/login">Login here</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminRegister;
