// /Applications/XAMPP/xamppfiles/htdocs/hotel-bougainvilla/src/components/ParentComponent.js

import React from 'react';
import Gallery from '../pages/Gallery';  // Correct path to Gallery.js
import GalleryUpdate from '../pages/admin/GalleryUpdate';  // Correct path to GalleryUpdate.js

function ParentComponent() {
    return (
        <div>
            <Gallery />
            <GalleryUpdate onImageUpload={(newImage) => {
                console.log('New image uploaded:', newImage);
                // Additional logic to handle the new image upload
            }} />
        </div>
    );
}

export default ParentComponent;
