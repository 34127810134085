import React from 'react';
import { useLocation } from 'react-router-dom';
import './Confirmation.css';
import ProgressBar from './ProgressBar';
import Footer from '../../components/Footer';

function Confirmation() {
    const location = useLocation();

    // Check if booking details are available
    if (!location.state || !location.state.bookingDetails) {
        return <p>Booking details not found.</p>;
    }

    const { room, adults, children, childrenAges, bookingDetails, paymentMethod } = location.state;

    return (
        <div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <ProgressBar currentStep="confirmation" />
            <div className="confirm-container">
                <div className="confirm-message">
                    <p>Your booking has been submitted successfully. We just sent you a confirmation email to {bookingDetails.email}.</p>
                </div>
                <div className="confirm-details">
                    <h2>Booking Details</h2>
                    <div className="confirm-detail-item">
                        <span className="confirm-detail-label">Transaction ID:</span>
                        <span className="confirm-detail-value">{Math.floor(Math.random() * 100000000)}</span>
                    </div>
                    <div className="confirm-detail-item">
                        <span className="confirm-detail-label">Email:</span>
                        <span className="confirm-detail-value">{bookingDetails.email}</span>
                    </div>
                    <div className="confirm-detail-item">
                        <span className="confirm-detail-label">City:</span>
                        <span className="confirm-detail-value">{bookingDetails.city}</span>
                    </div>
                    <div className="confirm-detail-item">
                        <span className="confirm-detail-label">Room:</span>
                        <span className="confirm-detail-value">{room.name}</span>
                    </div>
                    <div className="confirm-detail-item">
                        <span className="confirm-detail-label">Guests:</span>
                        <span className="confirm-detail-value">
                            {adults} Adults
                            {children > 0 && `, ${children} Children (${childrenAges.map(age => `${age} ${age === 1 ? 'year old' : 'years old'}`).join(', ')})`}
                        </span> {/* Display adults and children with their ages */}
                    </div>
                    <div className="confirm-detail-item">
                        <span className="confirm-detail-label">Check In:</span>
                        <span className="confirm-detail-value">{new Date(room.checkIn).toLocaleDateString()}</span>
                    </div>
                    <div className="confirm-detail-item">
                        <span className="confirm-detail-label">Check Out:</span>
                        <span className="confirm-detail-value">{new Date(room.checkOut).toLocaleDateString()}</span>
                    </div>
                    <div className="confirm-detail-item">
                        <span className="confirm-detail-label">Payment:</span>
                        <span className="confirm-detail-value">{paymentMethod}</span>
                    </div>
                    <div className="confirm-detail-item">
                        <span className="confirm-detail-label">Total Price:</span>
                        <span className="confirm-detail-value">${room.totalPrice.toFixed(2)}</span>
                    </div>
                </div>
                <div className="confirm-actions">
                    <button onClick={() => window.print()}>Print Booking Details</button>
                    <button onClick={() => window.location.href = '/'}>Return to Home Page</button>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Confirmation;
