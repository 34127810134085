import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import './Search.css';
import ProgressBar from './ProgressBar';
import { DateRange } from 'react-date-range';
import TopImage from '../../assets/Home/DSC_6678.JPG';

import format from 'date-fns/format';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { addDays } from 'date-fns';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import * as Icons from 'react-icons/fa';

import RoomImage1 from '../../assets/Rooms/1.jpg';
import RoomImage2 from '../../assets/RoomTypes/20240825_172032.jpg';
import RoomImage3 from '../../assets/Rooms/3.jpeg';
import RoomImage4 from '../../assets/Rooms/DSC_6163.JPG';
import RoomImage5 from '../../assets/Rooms/DSC_6133.JPG';
import RoomImage6 from '../../assets/Rooms/DSC_6140.JPG';
import RoomImage7 from '../../assets/RoomTypes/4-1.jpg';
import RoomImage8 from '../../assets/RoomTypes/3-1.jpg';
import RoomImage9 from '../../assets/Rooms/DSC_6125.JPG';
import RoomImage10 from '../../assets/Rooms/l1.jpg';
import RoomImage11 from '../../assets/Rooms/l2.jpg';
import RoomImage12 from '../../assets/Rooms/DSC_6206.JPG';
import RoomImage13 from '../../assets/Rooms/DSC_6164.JPG';
import RoomImage14 from '../../assets/Rooms/DSC_6121.JPG';
import RoomImage15 from '../../assets/Rooms/DSC_6124.JPG';
import RoomImage16 from '../../assets/Rooms/lt1.jpg';
import RoomImage17 from '../../assets/Rooms/lt2.jpg';
import RoomImage18 from '../../assets/Rooms/lt3.jpg';
import RoomImage19 from '../../assets/Rooms/DSC_6191.JPG';
import RoomImage20 from '../../assets/Rooms/DSC_6192.JPG';
import RoomImage21 from '../../assets/Rooms/l3.jpg';
import RoomImage22 from '../../assets/Rooms/DSC_6142.JPG';
import RoomImage23 from '../../assets/Rooms/DSC_6131.JPG';
import RoomImage24 from '../../assets/Rooms/DSC_6190.JPG';
import RoomImage25 from '../../assets/Rooms/ld.jpg';
import RoomImage26 from '../../assets/RoomTypes/20240825_172009.jpg';
import RoomImage27 from '../../assets/Rooms/DSC_6192.JPG';

const initialRooms = [
    {
        id: 1,
        name: 'Standard Single Room – Bed & Breakfast',
        price: 40.00,
        maxGuests: 1,
        image: RoomImage1,
        services: [
            { icon: 'FaBed', text: 'Bathroom 80 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 80 sq. ft.' },
            { icon: 'FaShower', text: 'Hot Water.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 2,
        name: 'Standard Single Room – Half Board',
        price: 44.00,
        maxGuests: 1,
        image: RoomImage2,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 3,
        name: 'Standard Single Room – Full Board',
        price: 47.00,
        maxGuests: 1,
        image: RoomImage3,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 4,
        name: 'Standard Double Room – Bed & Breakfast',
        price: 60.50,
        maxGuests: 2,
        image: RoomImage4,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 5,
        name: 'Standard Double Room – Half Board',
        price: 67.50,
        maxGuests: 2,
        image: RoomImage5,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 6,
        name: 'Standard Double Room – Full Board',
        price: 74.00,
        maxGuests: 2,
        image: RoomImage6,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 7,
        name: 'Standard Triple Room – Bed & Breakfast',
        price: 74.00,
        maxGuests: 3,
        image: RoomImage7,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 8,
        name: 'Standard Triple Room – Half Board',
        price: 80.50,
        maxGuests: 3,
        image: RoomImage8,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 9,
        name: 'Standard Triple Room – Full Board',
        price: 94.00,
        maxGuests: 3,
        image: RoomImage9,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 10,
        name: 'luxury Single Rooms – Bed & Breakfast',
        price: 47.00,
        maxGuests: 1,
        image: RoomImage10,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 11,
        name: 'luxury Single Room – Half Board',
        price: 54.00,
        maxGuests: 1,
        image: RoomImage11,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 12,
        name: 'luxury Single Room – Full Board',
        price: 60.50,
        maxGuests: 1,
        image: RoomImage12,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 13,
        name: 'luxury Double Room – Bed & Breakfast',
        price: 67.50,
        maxGuests: 2,
        image: RoomImage13,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 14,
        name: 'luxury Double Rooms – Half Board',
        price: 80.50,
        maxGuests: 2,
        image: RoomImage14,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 15,
        name: 'luxury Double Rooms – Full Board',
        price: 94.50,
        maxGuests: 2,
        image: RoomImage15,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 16,
        name: 'luxury Triple Rooms – Bed & Breakfast',
        price: 80.50,
        maxGuests: 3,
        image: RoomImage16,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 17,
        name: 'luxury Triple Rooms – Half Board',
        price: 107.50,
        maxGuests: 3,
        image: RoomImage17,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 18,
        name: 'luxury Triple Rooms – Full Board',
        price: 121.00,
        maxGuests: 3,
        image: RoomImage18,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 19,
        name: 'Apartment Triple Rooms – Full Board',
        price: 134.50,
        maxGuests: 3,
        image: RoomImage19,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 20,
        name: 'Apartment Triple Rooms – Half Board',
        price: 114.50,
        maxGuests: 3,
        image: RoomImage20,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 21,
        name: 'Apartment Triple Rooms – Bed & Breakfast',
        price: 87.50,
        maxGuests: 3,
        image: RoomImage21,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 22,
        name: 'Apartment Double Rooms – Full Board',
        price: 107.50,
        maxGuests: 2,
        image: RoomImage22,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 23,
        name: 'Apartment Double Rooms – Half Board',
        price: 94.00,
        maxGuests: 2,
        image: RoomImage23,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 24,
        name: 'Apartment Double Rooms – Bed & Breakfast',
        price: 74.00,
        maxGuests: 2,
        image: RoomImage24,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 25,
        name: 'Apartment Single Rooms – Full Board',
        price: 67.50,
        maxGuests: 1,
        image: RoomImage25,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 26,
        name: 'Apartment Single Rooms – Half Board',
        price: 60.50,
        maxGuests: 1,
        image: RoomImage26,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
    {
        id: 27,
        name: 'Apartment Single Rooms – Bed & Breakfast',
        price: 54.00,
        maxGuests: 1,
        image: RoomImage27,
        services: [
            { icon: 'FaBed', text: 'Bathroom 75 sq. ft.' },
            { icon: 'FaUtensils', text: 'Kitchen 70 sq. ft.' },
            { icon: 'FaShower', text: 'Bedroom 250 sq. ft.' },
            { icon: 'FaWifi', text: 'High Speed Internet' },
            { icon: 'FaColumns', text: 'Refrigerator' },
            { icon: 'FaAccusoft', text: 'Iron' },
            { icon: 'FaBed', text: 'Bed Room 300 square feet space' },
        ]
    },
];

function Search() {
    const [dateRange, setDateRange] = useState([{ startDate: new Date(), endDate: addDays(new Date(), 7), key: 'selection' }]);
    const [sortOrder, setSortOrder] = useState('default');
    const [priceRange, setPriceRange] = useState([33.5, 134.5]);
    const [currentPage, setCurrentPage] = useState(1);
    const [openCalendar, setOpenCalendar] = useState(false);
    const [adults, setAdults] = useState(1);
    const [children, setChildren] = useState(0);
    const [childrenAges, setChildrenAges] = useState([]);
    const [rooms, setRooms] = useState(1);
    const [displayedRooms, setDisplayedRooms] = useState(initialRooms);

    const roomsPerPage = 10;

    const filterRooms = useCallback(() => {
        // Calculate number of nights
        const numberOfNights = Math.max(Math.ceil((dateRange[0].endDate - dateRange[0].startDate) / (1000 * 60 * 60 * 24)), 1);

        // Calculate total price based on number of nights, price per night, and number of rooms
        const updatedRooms = initialRooms
            .map(room => ({
                ...room,
                totalPrice: room.price * numberOfNights * rooms,
                checkIn: dateRange[0].startDate,
                checkOut: dateRange[0].endDate,
                nights: numberOfNights
            }))
            .filter(room =>
                room.price >= priceRange[0] && room.price <= priceRange[1]
            );

        // Sort rooms based on the selected sort order
        const sortedRooms = updatedRooms.sort((a, b) => {
            if (sortOrder === 'lowest') return a.totalPrice - b.totalPrice;
            if (sortOrder === 'highest') return b.totalPrice - a.totalPrice;
            return a.id - b.id;
        });

        setDisplayedRooms(sortedRooms);
    }, [dateRange, priceRange, sortOrder, rooms]);

    useEffect(() => {
        filterRooms();
    }, [filterRooms]);

    const handleDateRangeChange = (ranges) => {
        setDateRange([ranges.selection]);
    };

    const handleSortChange = (e) => {
        setSortOrder(e.target.value);
    };

    const handlePriceChange = (value) => {
        setPriceRange(value);
    };

    const handleAdultsChange = (e) => {
        setAdults(parseInt(e.target.value, 10));
    };

    const handleChildrenChange = (e) => {
        const numberOfChildren = parseInt(e.target.value, 10);
        setChildren(numberOfChildren);

        // Adjust childrenAges array based on the number of children
        setChildrenAges(Array(numberOfChildren).fill(''));
    };

    const handleChildAgeChange = (index, value) => {
        const newChildrenAges = [...childrenAges];
        newChildrenAges[index] = value;
        setChildrenAges(newChildrenAges);
    };

    const handleRoomsChange = (e) => {
        setRooms(parseInt(e.target.value, 10));
    };

    const handleSearch = () => {
        filterRooms();
        setCurrentPage(1);
    };

    const paginatedRooms = displayedRooms.slice((currentPage - 1) * roomsPerPage, currentPage * roomsPerPage);

    const totalPages = Math.ceil(displayedRooms.length / roomsPerPage);

    const ServiceIcon = ({ iconName }) => {
        const IconComponent = Icons[iconName];
        return IconComponent ? <IconComponent /> : null;
    };

    return (
        <div className="search-page-container">
            <Navbar />
            <img src={TopImage} alt="Top Section" className="top-banner-image" />
            <h1 className="booking-heading">Book Now</h1>
            <ProgressBar currentStep="search" />

            <div className="booking-container">
                <div className="filter-container">
                    <div className="filter-item">
                        <label>Check In/Out</label>
                        <input
                            type="text"
                            readOnly
                            onClick={() => setOpenCalendar(!openCalendar)}
                            value={`${format(dateRange[0].startDate, 'dd/MM/yyyy')} → ${format(dateRange[0].endDate, 'dd/MM/yyyy')}`}
                        />
                        {openCalendar && (
                            <DateRange
                                ranges={dateRange}
                                onChange={handleDateRangeChange}
                                editableDateInputs={true}
                                moveRangeOnFirstSelection={false}
                                minDate={new Date()}
                            />
                        )}
                    </div>

                    <div className="filter-item">
                        <label>Adults</label>
                        <input
                            type="number"
                            min="1"
                            value={adults}
                            onChange={handleAdultsChange}
                        />
                    </div>

                    <div className="filter-item">
                        <label>Children</label>
                        <input
                            type="number"
                            min="0"
                            value={children}
                            onChange={handleChildrenChange}
                        />
                        {children > 0 && childrenAges.map((age, index) => (
                            <div key={index} className="child-age-select">
                                <label>Child {index + 1} Age</label>
                                <select value={age} onChange={(e) => handleChildAgeChange(index, e.target.value)}>
                                    <option value="">Select Age</option>
                                    {[...Array(18)].map((_, i) => (
                                        <option key={i} value={i}>{i} {i === 1 ? 'year old' : 'years old'}</option>
                                    ))}
                                </select>
                            </div>
                        ))}

                    </div>

                    <div className="filter-item">
                        <label>Rooms</label>
                        <input
                            type="number"
                            min="1"
                            value={rooms}
                            onChange={handleRoomsChange}
                        />
                    </div>

                    <div className="filter-item">
                        <label>Price</label>
                        <Slider
                            range
                            min={33.5}
                            max={134.5}
                            step={0.5}
                            value={priceRange}
                            onChange={handlePriceChange}
                            trackStyle={{ backgroundColor: '#D4A017' }}
                            handleStyle={[{ borderColor: '#D4A017', backgroundColor: '#D4A017' }, { borderColor: '#D4A017', backgroundColor: '#D4A017' }]}
                            railStyle={{ backgroundColor: '#d9d9d9' }}
                            dotStyle={{ borderColor: '#D4A017' }}
                            activeDotStyle={{ borderColor: '#D4A017' }}
                        />
                        <div className="price-range-display">
                            <span>${priceRange[0]}</span>
                            <span>${priceRange[1]}</span>
                        </div>
                    </div>

                    <button className="search-button" onClick={handleSearch}>Search</button>
                </div>

                <div className="room-list-container">
                    <div className="sort-container">
                        <label>Sort by:</label>
                        <select value={sortOrder} onChange={handleSortChange}>
                            <option value="default">Default</option>
                            <option value="lowest">Lowest Price</option>
                            <option value="highest">Highest Price</option>
                        </select>
                    </div>

                    {paginatedRooms.map(room => (
                        <div className="room-card-container" key={room.id}>
                            <img src={room.image} alt={room.name} className="room-image" />
                            <div className="room-details">
                                <h3 className="room-name">{room.name}</h3>
                                <p className="room-price">${room.price} PER NIGHT</p>
                                <p className="room-total-price">
                                    For ${room.totalPrice ? room.totalPrice.toFixed(2) : room.price.toFixed(2)} for {rooms} rooms
                                </p>
                                <div className="room-icons-container">
                                    {room.services.map((service, index) => (
                                        <div className="icon-with-tooltip" key={index}>
                                            <ServiceIcon iconName={service.icon} />
                                            <span className="tooltip-text">{service.text}</span>
                                        </div>
                                    ))}
                                </div>
                                <Link
                                    to={`/booking/booking`}
                                    state={{
                                        room: {
                                            id: room.id,
                                            name: room.name,
                                            price: room.price,
                                            totalPrice: room.totalPrice,
                                            image: room.image,
                                            checkIn: room.checkIn,
                                            checkOut: room.checkOut,
                                            nights: room.nights,
                                            services: room.services.map(service => ({ icon: service.icon, text: service.text })),
                                        },
                                        adults: adults, // Pass adults separately
                                        children: children, // Pass children separately
                                        childrenAges: childrenAges, // Pass childrenAges array
                                        rooms: rooms // Number of rooms
                                    }}
                                    style={{ textDecoration: 'none', color: 'inherit' }}
                                >
                                    <button className="room-button">Book Now</button>
                                </Link>


                            </div>
                        </div>
                    ))}

                    <div className="pagination-container">
                        <button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1}>Previous</button>
                        {[...Array(totalPages)].map((_, index) => (
                            <button key={index} onClick={() => setCurrentPage(index + 1)} className={currentPage === index + 1 ? 'active-page' : ''}>
                                {index + 1}
                            </button>
                        ))}
                        <button onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>Next</button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Search;