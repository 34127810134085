import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCR7QXIFCN45lPGkUDqVv4hVNnL6NTNi04",
    authDomain: "reviews-submission.firebaseapp.com",
    projectId: "reviews-submission",
    storageBucket: "reviews-submission.appspot.com",
    messagingSenderId: "1084042816836",
    appId: "1:1084042816836:web:a409329882a3631740fb11"
};

// Initialize Firebase only if no apps have been initialized yet
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

// Initialize Firebase Authentication, Firestore, and Storage
const auth = getAuth(app); // Make sure auth is initialized correctly
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
