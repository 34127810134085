import React, { useEffect, useState, useRef } from 'react';
import HomeTopSection from '../components/HomeTopSection';
import { Link } from 'react-router-dom';
import './Home.css';  // Import the Home.css file
import Footer from '../components/Footer';
import { motion } from 'framer-motion';  // Import Framer Motion
import companyVideo from '../assets/Home/stock-footage-cinnamon-sticks-and-cinnamon-powder-close-up.webm';

// Importing room images (Assuming you have these images)
import image1 from '../assets/Rooms/1.jpg';
import image2 from '../assets/RoomTypes/20240825_172032.jpg';
import image3 from '../assets/Rooms/3.jpeg';
import image4 from '../assets/Rooms/DSC_6163.JPG';
import image5 from '../assets/Rooms/DSC_6133.JPG';
import image6 from '../assets/Rooms/DSC_6140.JPG';
import image7 from '../assets/RoomTypes/4-1.jpg';
import image8 from '../assets/RoomTypes/3-1.jpg';
import image9 from '../assets/Rooms/DSC_6125.JPG';
import image10 from '../assets/Rooms/l1.jpg';
import image11 from '../assets/Rooms/l2.jpg';
import image12 from '../assets/Rooms/DSC_6206.JPG';
import image13 from '../assets/Rooms/DSC_6164.JPG';
import image14 from '../assets/Rooms/DSC_6121.JPG';
import image15 from '../assets/Rooms/DSC_6124.JPG';
import image16 from '../assets/Rooms/lt1.jpg';
import image17 from '../assets/Rooms/lt2.jpg';
import image18 from '../assets/Rooms/lt3.jpg';
import image19 from '../assets/Rooms/DSC_6191.JPG';
import image20 from '../assets/Rooms/DSC_6192.JPG';
import image21 from '../assets/Rooms/l3.jpg';
import image22 from '../assets/Rooms/DSC_6142.JPG';
import image23 from '../assets/Rooms/DSC_6131.JPG';
import image24 from '../assets/Rooms/DSC_6190.JPG';
import image25 from '../assets/Rooms/ld.jpg';
import image26 from '../assets/RoomTypes/20240825_172009.jpg';
import image27 from '../assets/Rooms/DSC_6192.JPG';

// Importing icons from react-icons
import { FaSpa } from 'react-icons/fa';
import { MdLocalLaundryService } from 'react-icons/md';
import { GiMeditation } from 'react-icons/gi';
import { FaSwimmer } from 'react-icons/fa';

// Importing new section images
import newImage1 from '../assets/Home/spa.jpg';
import newImage2 from '../assets/Home/Loundary.jpg';
import newImage3 from '../assets/Home/treatment.jpg';
import newImage4 from '../assets/Home/DSC_6182.JPG';

// Import your gallery images (update the paths as necessary)
import galleryImage1 from '../assets/Home/Gallery/DSC_6182.JPG';
import galleryImage2 from '../assets/Home/Gallery/christ.JPG';
import galleryImage3 from '../assets/Home/Gallery/59.jpg';
import galleryImage4 from '../assets/Home/Gallery/17.jpeg';
import galleryImage5 from '../assets/Home/Gallery/treatment.jpg';
import galleryImage6 from '../assets/Home/Gallery/hill.jpg';

function Home() {
    const rooms = [
        { name: "Standard Single Room – Bed & Breakfast", price: "$40.00 / night", img: image1, link: "./rooms/standard-single-bb" },
        { name: "Standard Single Room – Half Board", price: "$44.00 / night", img: image2, link: "./rooms/standard-single-hb" },
        { name: "Standard Single Room – Full Board", price: "$47.00 / night", img: image3, link: "./rooms/standard-single-fb" },
        { name: "Standard Double Room – Bed & Breakfast", price: "$60.50 / night", img: image4, link: "./rooms/standard-double-bb" },
        { name: "Standard Double Room – Half Board", price: "$67.50 / night", img: image5, link: "./rooms/standard-double-hb" },
        { name: "Standard Double Room – Full Board", price: "$74.00 / night", img: image6, link: "./rooms/standard-double-fb" },
        { name: "Standard Triple Room – Bed & Breakfast", price: "$74.00 / night", img: image7, link: "./rooms/standard-triple-bb" },
        { name: "Standard Triple Room – Half Board", price: "$80.50 / night", img: image8, link: "./rooms/standard-triple-hb" },
        { name: "Standard Triple Room – Full Board", price: "$94.00 / night", img: image9, link: "./rooms/standard-triple-fb" },
        { name: "luxury Single Rooms – Bed & Breakfast", price: "$47.00 / night", img: image10, link: "./rooms/luxury-single-bb" },
        { name: "luxury Single Room – Half Board", price: "$54.00 / night", img: image11, link: "./rooms/luxury-single-hb" },
        { name: "luxury Single Room – Full Board", price: "$60.50 / night", img: image12, link: "./rooms/luxury-single-fb" },
        { name: "luxury Double Room – Bed & Breakfast", price: "$67.50 / night", img: image13, link: "./rooms/luxury-double-bb" },
        { name: "luxury Double Rooms – Half Board", price: "$80.50 / night", img: image14, link: "./rooms/luxury-double-hb" },
        { name: "luxury Double Rooms – Full Board", price: "$94.50 / night", img: image15, link: "./rooms/luxury-double-fb" },
        { name: "luxury Triple Rooms – Bed & Breakfast", price: "$80.50 / night", img: image16, link: "./rooms/luxury-triple-bb" },
        { name: "luxury Triple Rooms – Half Board", price: "$107.50 / night", img: image17, link: "./rooms/luxury-triple-hb" },
        { name: "luxury Triple Rooms – Full Board", price: "$121.00 / night", img: image18, link: "./rooms/luxury-triple-fb" },
        { name: "Apartment Triple Rooms – Full Board", price: "$134.50 / night", img: image19, link: "./rooms/apartment-triple-fb" },
        { name: "Apartment Triple Rooms – Half Board", price: "$114.50 / night", img: image20, link: "./rooms/apartment-triple-hb" },
        { name: "Apartment Triple Rooms – Bed & Breakfast", price: "$87.50 / night", img: image21, link: "./rooms/apartment-triple-bb" },
        { name: "Apartment Double Rooms – Full Board", price: "$107.50 / night", img: image22, link: "./rooms/apartment-double-fb" },
        { name: "Apartment Double Rooms – Half Board", price: "$94.00 / night", img: image23, link: "./rooms/apartment-double-hb" },
        { name: "Apartment Double Rooms – Bed & Breakfast", price: "$74.00 / night", img: image24, link: "./rooms/apartment-double-bb" },
        { name: "Apartment Single Rooms – Full Board", price: "$67.50 / night", img: image25, link: "./rooms/apartment-single-fb" },
        { name: "Apartment Single Rooms – Half Board", price: "$60.50 / night", img: image26, link: "./rooms/apartment-single-hb" },
        { name: "Apartment Single Rooms – Bed & Breakfast", price: "$54.00 / night", img: image27, link: "./rooms/apartment-single-bb" },
    ];

    const galleryItems = [
        { name: "Swimming Pool", img: galleryImage1, link: "./Gallery" },
        { name: "Christmas", img: galleryImage2, link: "./Gallery" },
        { name: "Polonnaruwa", img: galleryImage3, link: "./Gallery" },
        { name: "Hotel", img: galleryImage4, link: "./Gallery" },
        { name: "Ayurveda Treatment Center", img: galleryImage5, link: "./Gallery" },
        { name: "Hill Country", img: galleryImage6, link: "./Gallery" },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const cardRef = useRef();

    const handleNext = () => {
        if (currentIndex < rooms.length - 4) {
            setCurrentIndex(currentIndex + 1);
            cardRef.current.style.transform = `translateX(-${(100 / 4) * (currentIndex + 1)}%)`;
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            cardRef.current.style.transform = `translateX(-${(100 / 4) * (currentIndex - 1)}%)`;
        }
    };

    // New section with four images and descriptions
    const imageSections = [
        { img: newImage1, description: "Ayurveda Spa - Rejuvenate your soul with traditional healing methods.", icon: <FaSpa /> },
        { img: newImage2, description: "Laundry Service - Hassle-free same-day laundry service.", icon: <MdLocalLaundryService /> },
        { img: newImage3, description: "Ayurveda Treatment Center - A combination of science and philosophy.", icon: <GiMeditation /> },
        { img: newImage4, description: "Swimming Pool - Refresh in our tropical-themed pool.", icon: <FaSwimmer /> },
    ];

    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % imageSections.length);
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval);
    }, [imageSections.length]);

    const feedbacksTop = [
        { name: "Laura", review: "Das zuvorkommende und äußert aufmerksame Servicepersonal sowie die wundervoll präsentierten und hervorragend schmeckenden Speisen des Kochs als auch die sauberen Zimmer machten unseren Aufenthalt im Hotel Bougainvilla auf Sri Lanka unvergesslich. Ein großes Kompliment!!! Bitte weiter so!!!", rating: 5 },
        { name: "Elfi Pühringer", review: "Sehr gerne teile ich etwas verspätet meine Eindrücke über meinen heurigen Aufenthalt hier im Bougainvilla mit.Es war mein 2. Mal u. wieder für 4 Wochen, die ich sehr genossen habe.Familiär geführtes kleineres Hotel mit überaus freundlichem u. jedem Wunsch ablesendem Personal. Die Gartenanlage wunderschön angelegt u. mit Pool. Genau das Richtige, um hier Ruhe u. Entspannung zu finden.Aber auch die Ausflugsprogramme lassen keine Wünsche offen.Ich kann dieses Hotel nur weiter empfehlen – u. möchte sicher einmal wiederkommen!", rating: 4 },
        { name: "Anita", review: "Wir waren sehr zufrieden. Das Personal war freundlich, hilfsbereit und ging auf unsere Wünsche ein. Die Anlage ist gepflegt, mit viel Grünpflanzen, die die Privatsphäre schützen. Der Pool lädt zum Baden ein und es hat Liegestühle zum Verweilen.Das Essen war sehr fein und mit Liebe gemacht. Der einzige minus Punkt war meine Glutenunverträglichkeit. Ich wurde zu wenig informiert was Mehl enthält oder eine Alternative wurde nicht bei jedem Gang geboten. Ich konnte aber immer fragen, was aber wegen der sprachprobleme nicht immer einfach war.", rating: 5 },
        { name: "Augustine", review: "Mein Mann und ich waren das erste Mal in Sri Lanka. Wir haben uns noch niemals im Urlaub so wohl gefühlt, wir in Bougain Villa. Angefangen vom Chef,der uns jeden Tag zum Frühstück begrüßte bis zum jeden Personal das einfach freundlich und hilfsbereit war. Einfach zum wohlfühlen. Wir können das Hotel nur weiterempfehlen! Das war sicher nicht unser letzter Besuch!", rating: 5 }
    ];

    const feedbacksBottom = [
        { name: "Nathalie et Yves CIVARD", review: "Nous sommes venus en janvier 2024. Le lieu est magnifique, le jardin luxuriant, les chambres spacieuses et bien tenues.Pradesh, le propriétaire, est un hôte extraordinaire, généreux, attentif à tous nos besoins. Nous lui disons un grand merci. Et tout le personnel est formidable!Nous n’avons qu’une seule envie, c’est de revenir.", rating: 5 },
        { name: "Helene Mergeler", review: "Eine wunderschöne Hotelanlage mit einem traumhaft gestalteten Garten mit Swimmingpool – mit einem Wort eine Wohlfühloase!Auffallend war auch das freundliche und zuvorkommend Personal. Das Essen mit frischen Obst und Meeresfrüchten war abwechslungsreich und lecker!Ich habe viele Orte der Welt kennen gelernt und Bougainvilla gehört eindeutig dazu.", rating: 5 },
        { name: "Stefan", review: "Die Hotelanlage ist wirklich schön gemacht, mit dem hübschen Garten, umrandet von den fast prunkvoll gestalteten doppelstöckigen Häusern im Kolonialstil. Auch die Zimmer an sich ließen für uns nichts zu wünschen übrig und waren im Gegensatz zu vielen anderen Hotels in Sri Lanka auf / über europäischem Standard, insbesondere was Sanitäranlagen angeht. Ein weiteres Highlight ist der Pool, an dem es sich wunderbar entspannen lässt. Insbesondere hervorzuheben ist allerdings die Aufmerksamkeit und Freundlichkeit des Personals, die wir in dem Maß in keinem anderen Hotel unserer Rundreise erlebt haben. Das Essen ist wirklich sehr gut, hier wird die landestypische Küche um viele Facetten erweitert und es kommen besondere und bis ins Detail durchdachte Menüs heraus.", rating: 5 },
        { name: "Sonja", review: "Ich komme seit Jänner 2016 jedes Jahr bzw. sogar manchmal war ich sogar zweimal im Jahr im Hotel Bougainvilla. Es ist einfach wunderschön und das Personal ist so etwas von zuvorkommend und erfüllt jeden Wunsch. Ich kann das Hotel jeden empfehlen sowie auch das Reiseangebot für eine Rundreise durch das wunderschöne Sri Lanka. Auch bei Rundreisen wird auf jeden Wunsch persönlich eingegangen.", rating: 4 }
    ];

    const [isHovered, setIsHovered] = useState(false);

    return (
        <div>
            <HomeTopSection />
            <h1 className="room-section-title">Our Room Types</h1>
            <h3 className="room-section-second">Check out now our best rooms</h3>
            <div className="room-cards-container">
                <button className="carousel-button left" onClick={handlePrev}>←</button>
                <div className="room-cards-wrapper" ref={cardRef} style={{ transform: `translateX(-${currentIndex * (100 / 4)}%)` }}>
                    {rooms.map((room, index) => (
                        <Link to={room.link} key={index} className="room-card">
                            <img src={room.img} alt={room.name} />
                            <h2>{room.name}</h2>
                            <p className="room-price">{room.price}</p>
                        </Link>
                    ))}
                </div>
                <button className="carousel-button right" onClick={handleNext}>→</button>
            </div>
            <h1 className="image-description-title">Our Services</h1>
            <h3 className="image-description-second">Check out our awesome services</h3>
            <div className="image-description-container">
                <div className="image-column">
                    {imageSections.map((section, index) => (
                        <img
                            key={index}
                            src={section.img}
                            alt={section.description}
                            className={`image-slide ${index === activeIndex ? 'active' : ''}`}
                            style={{ display: index === activeIndex ? 'block' : 'none' }}
                        />
                    ))}
                </div>
                <div className="vertical-separator"></div>
                <div className="description-column">
                    {imageSections.map((section, index) => (
                        <div
                            key={index}
                            className={`description ${index === activeIndex ? 'highlighted' : ''}`}
                        >
                            {section.icon} {section.description}
                        </div>
                    ))}
                </div>
            </div>

            {/* Feedback Section */}
            <h1 className="feedback-section-title">What Our Guests Are Saying</h1>
            <div className="feedback-section">
                <motion.div
                    className="feedback-cards top"
                    animate={{ x: isHovered ? 0 : ['0%', '-100%'] }}
                    transition={{
                        repeat: Infinity,
                        repeatType: "loop",
                        ease: "linear",
                        duration: 20
                    }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    {feedbacksTop.map((feedback, index) => (
                        <div key={index} className="feedback-card">
                            <h3>{feedback.name}</h3>
                            <p>{feedback.review}</p>
                            <p>{'⭐'.repeat(feedback.rating)}</p>
                        </div>
                    ))}
                </motion.div>
                <motion.div
                    className="feedback-cards bottom"
                    animate={{ x: isHovered ? 0 : ['0%', '100%'] }}
                    transition={{
                        repeat: Infinity,
                        repeatType: "loop",
                        ease: "linear",
                        duration: 20
                    }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    {feedbacksBottom.map((feedback, index) => (
                        <div key={index} className="feedback-card">
                            <h3>{feedback.name}</h3>
                            <p>{feedback.review}</p>
                            <p>{'⭐'.repeat(feedback.rating)}</p>
                        </div>
                    ))}
                </motion.div>
            </div>

            {/* Gallery Section */}
            <h1 className="gallery-section-title">Gallery</h1>
            <h3 className="gallery-section-second">Hotel Bougainvilla Image Gallery</h3>
            <div className="gallery-cards-container">
                {galleryItems.map((item, index) => (
                    <Link to={item.link} key={index} className="gallery-card">
                        <img src={item.img} alt={item.name} />
                        <h2>{item.name}</h2>
                    </Link>
                ))}
            </div>
            {/* Our Other Companies Section */}
            <h1 className="company-section-title">Our Other Companies</h1>
            <h2 className="company-section-link">
                <a href="https://hiruceylonspice.com" target="_blank" rel="noopener noreferrer">
                    hiruceylonspice.com
                </a>
            </h2>
            <div className="company-video-container">
                <video className="company-video" src={companyVideo} muted loop autoPlay playsInline></video>
            </div>
            <Footer />
        </div>
    );
}

export default Home;
