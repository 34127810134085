import React, { useState, useEffect } from 'react';
import './Reviews.css';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import ReviewsImage from '../assets/Reviews/17.jpeg';
import Image1 from '../assets/Reviews/WhatsApp Image 2024-08-13 at 12.49.43.jpeg';
import Image2 from '../assets/Reviews/e2.jpeg';
import Image3 from '../assets/Reviews/e.jpeg';
import Image4 from '../assets/Reviews/DSC_6241.JPG';
import Image5 from '../assets/Reviews/e3.jpeg';

// Firebase imports
import { db } from '../firebaseConfig';
import { collection, addDoc, getDocs } from "firebase/firestore";

function Reviews() {
    const [reviews, setReviews] = useState([]);
    const [newReview, setNewReview] = useState({
        title: "",
        text: "",
        name: "",
        email: "",
        rating: 0,
        images: [],
        date: new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
    });

    useEffect(() => {
        // Fetch reviews from Firestore on component mount
        const fetchReviews = async () => {
            const querySnapshot = await getDocs(collection(db, "reviews"));
            const reviewsData = querySnapshot.docs.map(doc => doc.data());
            setReviews(reviewsData);
        };

        fetchReviews();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newReview.rating === 0) {
            alert("Please select a star rating.");
            return;
        }

        try {
            // Save review to Firestore
            const docRef = await addDoc(collection(db, "reviews"), newReview);
            console.log("Document written with ID: ", docRef.id);
            setReviews([newReview, ...reviews]);

            setNewReview({
                title: "",
                text: "",
                name: "",
                email: "",
                rating: 0,
                images: [],
                date: new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
            });
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    };

    const handleChange = (e) => {
        setNewReview({ ...newReview, [e.target.name]: e.target.value });
    };

    const handleRating = (rating) => {
        setNewReview({ ...newReview, rating });
    };

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        const fileReaders = files.map(file => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = () => reject(new Error("Failed to read file"));
                reader.readAsDataURL(file); // Convert file to base64 string
            });
        });

        Promise.all(fileReaders).then(imageUrls => {
            setNewReview({ ...newReview, images: imageUrls });
        }).catch(error => console.error("Error loading images:", error));
    };

    return (
        <div className="reviews-top-section">
            <Navbar />
            <div className="reviews-image-container">
                <img src={ReviewsImage} alt="Beautiful garden view" className="reviews-image" />
                <h1 className="reviews-heading">OUR GUEST REVIEWS</h1>
            </div>
            <div className="reviews-main-section">
                <div className="reviews-form-container">
                    <form onSubmit={handleSubmit} className="reviews-form">
                        <div className="rating">
                            <label>Your overall rating</label>
                            <div className="stars">
                                {[1, 2, 3, 4, 5].map((star) => (
                                    <span
                                        key={star}
                                        className={newReview.rating >= star ? "filled-star" : "empty-star"}
                                        onClick={() => handleRating(star)}
                                    >★</span>
                                ))}
                            </div>
                        </div>
                        <input
                            type="text"
                            name="title"
                            value={newReview.title}
                            onChange={handleChange}
                            placeholder="Title of your review"
                            className="review-input"
                            required
                        />
                        <textarea
                            name="text"
                            value={newReview.text}
                            onChange={handleChange}
                            placeholder="Tell people your review"
                            className="review-textarea"
                            required
                        ></textarea>
                        <input
                            type="text"
                            name="name"
                            value={newReview.name}
                            onChange={handleChange}
                            placeholder="Your name"
                            className="review-input"
                            required
                        />
                        <input
                            type="email"
                            name="email"
                            value={newReview.email}
                            onChange={handleChange}
                            placeholder="Your email"
                            className="review-input"
                            required
                        />
                        <div className="photo-upload">
                            <label>Do you have photos to share?</label>
                            <input
                                type="file"
                                name="photos"
                                accept="image/*"
                                multiple
                                className="review-input"
                                onChange={handleImageChange}
                            />
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" required />
                            <label>This review is based on my own experience and is my genuine opinion.</label>
                        </div>
                        <button type="submit" className="submit-review-button">SUBMIT YOUR REVIEW</button>
                    </form>
                    <div className="image-gallery">
                        <img src={Image1} alt="Bougainvillea garden" />
                        <img src={Image2} alt="Swimming pool area" />
                        <img src={Image3} alt="Hotel garden with lush greenery" />
                        <img src={Image4} alt="Palm trees" />
                        <img src={Image5} alt="Sunset view" />
                    </div>
                </div>
                <div className="reviews-list-container">
                    {reviews.map((review, index) => (
                        <div key={index} className="single-review">
                            <h3>{review.title}</h3>
                            <div className="rating-date">
                                <span>{'★'.repeat(review.rating)}</span>
                                <span>{review.date}</span>
                            </div>
                            <p>{review.text}</p>
                            {review.images && review.images.length > 0 && (
                                <div className="review-images">
                                    {review.images.map((image, idx) => (
                                        <img key={idx} src={image} alt={`Review ${idx + 1}`} />
                                    ))}
                                </div>
                            )}
                            <p className="review-author">- {review.name}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Reviews;
