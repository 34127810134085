import React from 'react';
import './Sitemap.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function Sitemap() {
    return (
        <div className="sitemap-container">
            <Navbar />
            <div className="sitemap-content">
                <h1>Hotel Bougainvilla Sitemap</h1>
                <div className="sitemap">
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/about-us">About Us</a></li>
                        <li>
                            <a href="/rooms">Rooms</a>
                            <ul>
                                <li><a href="/rooms/rooms-list">Rooms List</a></li>
                                <li><a href="/rooms/services">Services</a></li>
                            </ul>
                        </li>
                        <li><a href="/gallery">Gallery</a></li>
                        <li><a href="/reviews">Reviews</a></li>
                        <li><a href="/contact-us">Contact Us</a></li>
                        <li><a href="/booking">Book Online</a></li>
                    </ul>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Sitemap;
