import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Ensure the path is correct

function ProtectedRoute({ element }) {
    const { isAuthenticated, loading } = useAuth();

    console.log('ProtectedRoute: isAuthenticated:', isAuthenticated);
    console.log('ProtectedRoute: loading:', loading);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        return <Navigate to="/admin/login" />;
    }

    return element;
}

export default ProtectedRoute;
