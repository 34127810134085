import React from 'react';
import './AboutUs.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import aboutImage from '../assets/Aboutus/17.jpeg';
import rightImage1 from '../assets/Aboutus/7.jpeg'; // Replace with the actual path to your images
import rightImage2 from '../assets/Aboutus/DSC_6678.JPG'; // Replace with the actual path to your images

function AboutUs() {
    return (
        <div className="about-top-section">
            <Navbar />
            <div className="about-image-container">
                <img src={aboutImage} alt="About Us" className="about-image" />
                <h1 className="about-heading">ABOUT OUR HOTEL</h1>
            </div>

            <div className="about-content-section">
                <div className="about-left-column">
                    <h2>Hotel Bougainvilla</h2>
                    <h3>Home for the best holiday vacation</h3>
                    <p>
                        Hotel Bougainvilla is a very nice hotel located in a quiet charming rural atmosphere in the village of Kaluwamodara, 91 Km from Colombo airport. Free airport transportation will be provided to those who book single room more than 21 days and those who book double room more than 14 days.. Prabath and his attentive team will formally give you heartfelt, warm traditional welcome with delicious fresh fruit juice leaving a lasting impression in your mind. The Bouganvilla – a charming recently built Villa in separate Villa and separate garden, typical colonial style consists of 25 rooms with extra tall beds en suite shower and toilet as well as air – conditioning. Of course, you may use our WIFI internet free of charge. While enjoying the pool with sun beds you may watch a large variety of birds feeding on the flowers neatly grown around. It is a mere 15 minutes walk from here to the magnificent beach or if you prefer you may avail yourself of a free ride in the hotel tuk – tuk. In a friendly courteous atmosphere our active and attentive staff will offer you besides daily fresh fruit a very diverse menu with tasty freshly made meals amongst others wellness programs with individually designed Ayurveda treatment and tailor made excursions in our own minibus.
                    </p>
                </div>
                <div className="about-right-column">
                    <img src={rightImage1} alt="" className="right-image" />
                    <img src={rightImage2} alt="" className="right-image" />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default AboutUs;
