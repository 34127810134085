import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminLogin.css';
import hotelLogo from '../../assets/Navbar/Bougainvilla.png';
import { useAuth } from '../../components/AuthContext'; // Import useAuth from AuthContext

function AdminLogin() {
    const [email, setEmail] = useState(''); // State for storing email input
    const [password, setPassword] = useState(''); // State for storing password input
    const [error, setError] = useState(null); // State for storing errors
    const navigate = useNavigate(); // Used for navigation after login

    const { login } = useAuth(); // Get the login function from AuthContext

    const handleLogin = async (e) => {
        e.preventDefault(); // Prevents form submission from refreshing the page
        setError(null); // Clear any existing errors

        try {
            console.log("Attempting to log in with email:", email); // Log email being used for login
            await login(email, password); // Use the login function from AuthContext
            navigate('/admin/dashboard'); // Navigate to the dashboard on successful login
        } catch (error) {
            console.error("Login failed:", error); // Log any errors that occur during login
            setError('Login failed: ' + error.message); // Set error state to display the error message
        }
    };

    return (
        <div className="admin-login-container">
            <div className="admin-login-left">
                <img src={hotelLogo} alt="Hotel Logo" />
                <h2>HOTEL BOUGAINVILLA</h2>
            </div>
            <div className="admin-login-right">
                <div className="admin-login-box">
                    <h1 className="admin-login-title">ADMIN LOGIN</h1>
                    <form className="admin-login-form" onSubmit={handleLogin}>
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} // Update email state on input change
                            className="admin-login-input"
                            required
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)} // Update password state on input change
                            className="admin-login-input"
                            required
                        />
                        {error && <p className="error-message">{error}</p>} {/* Display any error messages */}
                        <p className="forgot-password">Forgot Password?</p>
                        <button type="submit" className="admin-login-button">Login</button>
                    </form>
                    <div className="admin-login-link">
                        <p>Don't have an account? <a href="/admin/register">Register here</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminLogin;
