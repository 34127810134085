import React from 'react';
import './PrivacyPolicy.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function PrivacyPolicy() {
    return (
        <div className="privacy-container">
            <Navbar />
            <div className="privacy-content">
                <h1 className="privacy-heading">Privacy Policy</h1>
                <p className="privacy-date">Last updated: August 18, 2024</p>

                <p className="privacy-text">
                    At Hotel Bougainvilla, we are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner.
                    This Privacy Policy outlines the types of information we collect from you, how we use it, and the measures we take to protect your data.
                </p>

                <h2 className="privacy-subheading">1. Information We Collect</h2>
                <p className="privacy-text">
                    We collect personal information from you when you:
                </p>
                <ul className="privacy-list">
                    <li>Make a booking or reservation online or by phone</li>
                    <li>Register for our services or create an account</li>
                    <li>Subscribe to our newsletter or promotional offers</li>
                    <li>Contact us through our website or customer service</li>
                    <li>Participate in surveys, contests, or promotional activities</li>
                </ul>
                <p className="privacy-text">
                    The types of personal information we may collect include:
                </p>
                <ul className="privacy-list">
                    <li>Full name</li>
                    <li>Email address</li>
                    <li>Phone number</li>
                    <li>Billing and payment information</li>
                    <li>Home address or other contact information</li>
                    <li>Guest preferences and special requests</li>
                    <li>IP address and browsing data (collected through cookies)</li>
                </ul>

                <h2 className="privacy-subheading">2. How We Use Your Information</h2>
                <p className="privacy-text">
                    We use the personal information we collect to:
                </p>
                <ul className="privacy-list">
                    <li>Process bookings and reservations</li>
                    <li>Provide customer service and support</li>
                    <li>Personalize your experience at Hotel Bougainvilla</li>
                    <li>Send you booking confirmations, updates, and offers</li>
                    <li>Improve our website, services, and marketing efforts</li>
                    <li>Comply with legal and regulatory requirements</li>
                </ul>

                <h2 className="privacy-subheading">3. Cookies and Tracking Technologies</h2>
                <p className="privacy-text">
                    We use cookies and similar tracking technologies to enhance your experience on our website. Cookies help us to understand how you interact with our website,
                    analyze traffic, and remember your preferences for future visits. You can control or disable cookies through your browser settings, but this may affect some functionality of our website.
                </p>

                <h2 className="privacy-subheading">4. Data Security</h2>
                <p className="privacy-text">
                    Hotel Bougainvilla takes the security of your personal information seriously. We use industry-standard encryption methods and security measures to protect your data from unauthorized access,
                    disclosure, or loss. However, please note that no method of transmission over the Internet or method of electronic storage is completely secure.
                </p>

                <h2 className="privacy-subheading">5. Third-Party Sharing</h2>
                <p className="privacy-text">
                    We may share your information with trusted third-party service providers who help us operate our hotel and website, process payments, or deliver services.
                    These providers are required to protect your information and use it only for the purposes for which it was shared. We do not sell or rent your personal data to third parties for marketing purposes.
                </p>

                <h2 className="privacy-subheading">6. Data Retention</h2>
                <p className="privacy-text">
                    We will retain your personal information for as long as necessary to fulfill the purposes for which it was collected, comply with legal obligations, resolve disputes, and enforce agreements.
                </p>

                <h2 className="privacy-subheading">7. Your Rights</h2>
                <p className="privacy-text">
                    You have the right to:
                </p>
                <ul className="privacy-list">
                    <li>Access the personal information we hold about you</li>
                    <li>Request corrections to inaccurate or incomplete information</li>
                    <li>Request the deletion of your personal data, subject to certain conditions</li>
                    <li>Withdraw consent to the use of your data, where applicable</li>
                    <li>Opt out of marketing communications at any time</li>
                </ul>
                <p className="privacy-text">
                    To exercise any of these rights, please contact us at [insert email or contact information].
                </p>

                <h2 className="privacy-subheading">8. Changes to This Policy</h2>
                <p className="privacy-text">
                    We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. Any updates will be posted on this page with the effective date of the revision.
                    We encourage you to review this policy periodically.
                </p>

                <h2 className="privacy-subheading">9. Contact Us</h2>
                <p className="privacy-text">
                    If you have any questions about this Privacy Policy or the way we handle your personal information, please contact us at:
                </p>
                <p className="privacy-text">
                    Hotel Bougainvilla <br />
                    Email: info@bougainvilla.com <br />
                    Phone: +1 (123) 456-7890 <br />
                    Address: Hotel Bougainvilla, Sripathi Road, Kaluwamodara, Aluthgama, Sri Lanka
                </p>
            </div>
            <Footer />
        </div>
    );
}

export default PrivacyPolicy;
