import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../assets/Navbar/Bougainvilla.png'; // Replace with your actual logo path

function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <img src={logo} alt="Hotel Logo" />
            </div>
            <div className="menu-icon" onClick={toggleMenu}>
                ☰ menu
            </div>
            <ul className={`navbar-links ${menuOpen ? 'active' : ''}`}>
                <div className="close-icon" onClick={closeMenu}>
                    ✖
                </div>
                <li><Link to="/" onClick={closeMenu}>Home</Link></li>
                <li><Link to="/about-us" onClick={closeMenu}>About Us</Link></li>
                <li className="dropdown">
                    <Link to="/rooms" onClick={closeMenu}>Rooms</Link>
                    <ul className="dropdown-content">
                        <li><Link to="/rooms/rooms-list" onClick={closeMenu}>Rooms List</Link></li>
                        <li><Link to="/rooms/services" onClick={closeMenu}>Services</Link></li>
                    </ul>
                </li>
                <li><Link to="/gallery" onClick={closeMenu}>Gallery</Link></li>
                <li><Link to="/reviews" onClick={closeMenu}>Reviews</Link></li>
                <li><Link to="/contact-us" onClick={closeMenu}>Contact Us</Link></li>
                <li className="book-online"><Link to="/booking" onClick={closeMenu}>Book Online</Link></li>
            </ul>
        </nav>
    );
}

export default Navbar;
