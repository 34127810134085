import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import './Rooms.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import roomVideo from '../assets/Rooms/WhatsApp Video 2024-08-10 at 21.36.14.mp4'; // Import your video file here
import image1 from '../assets/Rooms/DSC_6136.JPG'; // Replace with your actual image paths
import image2 from '../assets/Rooms/DSC_6190.JPG'; // Replace with your actual image paths

function Rooms() {
    return (
        <>
            <div className='rooms-top-section'>
                <Navbar />
                <div className='vid-ovlay'>
                    <video className='rooms-video' autoPlay loop muted>
                        <source src={roomVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className='rooms-heading-container'>
                        <h1 className='rooms-heading'>Discover the Perfect Room for Every Stay</h1>
                        <p className='rooms-caption'>At Hotel Bougainvilla, our rooms offer a blend of comfort and elegance, designed to cater to your every need. Whether you’re seeking luxury, tranquility, or a cozy retreat, we have the perfect space to enhance your stay.</p>
                        <Link to="/contact-us" className='rooms-contact-link'>CONTACT US</Link>
                    </div>
                </div>
            </div>
            <div className='rooms'>
                <h1>BOUGAINVILLA</h1>
                <nav>
                    <Link to="rooms-list">Rooms List</Link>
                    <Link to="services">Services</Link>
                </nav>
                <Outlet />
            </div>
            <div className='roo-description'>
                <div className='roo-description-left'>
                    <p>At Hotel Bougainvilla, we offer an exquisite selection of rooms designed to provide the ultimate in comfort and luxury. Each room is a serene retreat, blending modern amenities with elegant decor that reflects the charm of our natural surroundings. Whether you’re here for a romantic getaway, a family vacation, or a business trip, our rooms cater to all your needs with plush bedding, spacious layouts, and stunning views of the garden or the cityscape.</p>
                    <p>Enjoy a restful night’s sleep in our carefully curated spaces, each equipped with high-speed Wi-Fi, flat-screen TVs, and a well-appointed en-suite bathroom. Our attention to detail ensures that every aspect of your stay is infused with warmth and hospitality, making your experience at Hotel Bougainvilla truly memorable.</p>
                    <p>From our cozy Standard Rooms to our luxurious Suites, discover the perfect accommodation that suits your style and preferences. Indulge in the elegance of Hotel Bougainvilla and let us be your home away from home.</p>
                    <p>This description emphasizes the comfort, elegance, and variety of room options available at Hotel Bougainvilla, appealing to a wide range of guests.</p>
                    <p>n addition to our in-room features, Hotel Bougainvilla offers personalized services to ensure your stay is seamless. Our dedicated staff is always on hand to cater to your requests, whether it’s arranging transportation, setting up a romantic dinner in your room, or assisting with special requirements. We take pride in delivering exceptional service and comfort, making sure every guest feels valued and at ease. Come and immerse yourself in the tranquil ambiance of Hotel Bougainvilla, where luxury meets heartfelt hospitality.</p>
                </div>
                <div className='roo-description-right'>
                    <img src={image1} alt='Room view 1' className='roo-image' />
                    <img src={image2} alt='Room view 2' className='roo-image' />
                </div>
            </div>

            {/* New Section: Our Current Rooms Rate */}
            <div className='current-rooms-rate'>
                <h2>Our Current Room Rates</h2>
                <div className='rates-container'>
                    <div className='rates-left'>
                        <table className='rate-table'>
                            <tr>
                                <td rowspan="2">
                                    <img src={image1} alt="Bougainvilla" className='table-image' />
                                </td>
                                <td colspan="3">BOUGAINVILLA PRICE LIST till December 30th 2024 - 01 April 2025</td>
                            </tr>
                            <tr>
                                <td>PRICE PER PERSON for 1 person occupancy</td>
                                <td>PRICE PER PERSON for 2 people occupancy</td>
                                <td>PRICE PER PERSON for 3 people occupancy</td>
                            </tr>
                            <tr>
                                <td>STANDARD DOUBLE ROOM with breakfast</td>
                                <td>50 USD</td>
                                <td>60 USD</td>
                                <td>70 USD</td>
                            </tr>
                            <tr>
                                <td>STANDARD DOUBLE ROOM with breakfast & dinner</td>
                                <td>60 USD</td>
                                <td>70 USD</td>
                                <td>80 USD</td>
                            </tr>
                            <tr>
                                <td>LUXURY DOUBLE ROOM with breakfast</td>
                                <td>55 USD</td>
                                <td>65 USD</td>
                                <td>75 USD</td>
                            </tr>
                            <tr>
                                <td>LUXURY DOUBLE ROOM with breakfast and dinner</td>
                                <td>65 USD</td>
                                <td>75 USD</td>
                                <td>85 USD</td>
                            </tr>

                        </table>
                    </div>
                    <div className='rates-right'>
                        <table className='rate-table'>
                            <tr>
                                <td rowspan="2">
                                    <img src={image2} alt="Bougainvilla" className='table-image' />
                                </td>
                                <td colspan="3">BOUGAINVILLA PRICE LIST till November 30, 2024</td>
                            </tr>
                            <tr>
                                <td>PRICE PER PERSON for 1 person occupancy</td>
                                <td>PRICE PER PERSON for 2 people occupancy</td>
                                <td>PRICE PER PERSON for 3 people occupancy</td>
                            </tr>
                            <tr>
                                <td>STANDARD DOUBLE ROOM with breakfast</td>
                                <td>40 USD</td>
                                <td>47 USD</td>
                                <td>59 USD</td>
                            </tr>
                            <tr>
                                <td>STANDARD DOUBLE ROOM with breakfast & dinner</td>
                                <td>48 USD</td>
                                <td>56 USD</td>
                                <td>70 USD</td>
                            </tr>
                            <tr>
                                <td>LUXURY DOUBLE ROOM with breakfast</td>
                                <td>44 USD</td>
                                <td>52 USD</td>
                                <td>64 USD</td>
                            </tr>
                            <tr>
                                <td>LUXURY DOUBLE ROOM with breakfast and dinner</td>
                                <td>52 USD</td>
                                <td>60 USD</td>
                                <td>74 USD</td>
                            </tr>

                        </table>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}

export default Rooms;
