import React, { useEffect, useState } from 'react';
import './HomeTopSection.css';
import Navbar from './Navbar';
import { FaWifi, FaTv, FaUtensils } from 'react-icons/fa'; // Importing icons from react-icons
import image1 from '../assets/HomeTopSection/17.jpeg';
import image2 from '../assets/HomeTopSection/DSC_6678.JPG';
import image3 from '../assets/HomeTopSection/13.jpeg';
import image4 from '../assets/HomeTopSection/6.jpeg';
import image5 from '../assets/HomeTopSection/DSC_6185.JPG';

function HomeTopSection() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const images = [image1, image2, image3, image4, image5];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
        }, 5000); // Change slide every 5 seconds
        return () => clearInterval(interval);
    }, [images.length]);

    return (
        <div className="home-top-section">
            <Navbar />
            <div className="slideshow">
                {images.map((image, index) => (
                    <div
                        key={index}
                        className={`slide ${index === currentSlide ? 'active' : ''}`}
                        style={{ backgroundImage: `url(${image})` }}
                    >
                        <h1 className="slide-title">WELCOME TO HOTEL BOUGAINVILLA</h1>
                        <div className="cards-container">
                            <div className="cards">
                                <div className="cards-icon">
                                    <FaWifi size={30} />
                                </div>
                                <div className="cards-text">
                                    <h2>ENJOY FREE WIFI</h2>
                                    <p>IN THE WHOLE HOTEL</p>
                                </div>
                            </div>
                            <div className="cards">
                                <div className="cards-icon">
                                    <FaTv size={30} />
                                </div>
                                <div className="cards-text">
                                    <h2>CABLE TELEVISION</h2>
                                    <p>FREE CABLE TV</p>
                                </div>
                            </div>
                            <div className="cards">
                                <div className="cards-icon">
                                    <FaUtensils size={30} />
                                </div>
                                <div className="cards-text">
                                    <h2>RESTAURANT</h2>
                                    <p>RESTAURANT & BAR</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="slide-indicators">
                {images.map((_, index) => (
                    <div
                        key={index}
                        className={`indicator ${index === currentSlide ? 'active' : ''}`}
                        onClick={() => setCurrentSlide(index)}
                    ></div>
                ))}
            </div>
        </div>
    );
}

export default HomeTopSection;