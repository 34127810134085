import React, { useState, useEffect } from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import backgroundImage from '../../assets/RoomTypes/DSC_6193.JPG';
import { FaUserFriends, FaCalendarDay, FaBed, FaRulerCombined, FaDoorOpen } from 'react-icons/fa';
import { FaWifi, FaCoffee, FaTv, FaFan, FaMusic, FaSnowflake, FaBath, FaCocktail } from 'react-icons/fa';
import './StandardSingleRoomBB.css';

import image1 from '../../assets/RoomTypes/lx21-copy.jpg';
import image2 from '../../assets/RoomTypes/DSC_6193.JPG';
import image3 from '../../assets/RoomTypes/lx19-copy.jpg';
import image4 from '../../assets/RoomTypes/20240825_172045.jpg';
import image5 from '../../assets/RoomTypes/20240825_172144.jpg';
import image6 from '../../assets/RoomTypes/lx34-copy.jpg';

// Import images for the other-rooms section
import otherRoomImage1 from '../../assets/RoomTypes/DSC_6193.JPG';
import otherRoomImage2 from '../../assets/RoomTypes/DSC_6204.JPG';

function LuxuryTripleRoomFB() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const images = [image1, image2, image3, image4, image5, image6];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000); // Change image every 3 seconds
        return () => clearInterval(interval);
    }, [images.length]);

    const roomList = [
        { name: "Standard Single Room – Bed & Breakfast", link: "./standard-single-bb" },
        { name: "Standard Single Room – Half Board", link: "./standard-single-hb" },
        { name: "Standard Single Room – Full Board", link: "./standard-single-fb" },
        { name: "Standard Double Room – Bed & Breakfast", link: "./standard-double-bb" },
        { name: "Standard Double Room – Half Board", link: "./standard-double-hb" },
        { name: "Standard Double Room – Full Board", link: "./standard-double-fb" },
        { name: "Standard Triple Room – Bed & Breakfast", link: "./standard-triple-bb" },
        { name: "Standard Triple Room – Half Board", link: "./standard-triple-hb" },
        { name: "Standard Triple Room – Full Board", link: "./standard-triple-fb" },
        { name: "Luxury Single Room – Bed & Breakfast", link: "./luxury-single-bb" },
        { name: "Luxury Single Room – Half Board", link: "./luxury-single-hb" },
        { name: "Luxury Single Room – Full Board", link: "./luxury-single-fb" },
        { name: "Luxury Double Rooms – Bed & Breakfast", link: "./luxury-double-bb" },
        { name: "Luxury Double Rooms – Half Board", link: "./luxury-double-hb" },
        { name: "Luxury Double Rooms – Full Board", link: "./luxury-double-fb" },
        { name: "Luxury Triple Rooms – Bed & Breakfast", link: "./luxury-triple-bb" },
        { name: "Luxury Triple Rooms – Half Board", link: "./luxury-triple-hb" },
        { name: "Apartment Triple Rooms – Full Board", link: "./apartment-triple-fb" },
        { name: "Apartment Triple Rooms – Half Board", link: "./apartment-triple-hb" },
        { name: "Apartment Triple Rooms – Bed & Breakfast", link: "./apartment-triple-bb" },
        { name: "Apartment Double Rooms – Full Board", link: "./apartment-double-fb" },
        { name: "Apartment Double Rooms – Half Board", link: "./apartment-double-hb" },
        { name: "Apartment Double Rooms – Bed & Breakfast", link: "./apartment-double-bb" },
        { name: "Apartment Single Rooms – Full Board", link: "./apartment-single-fb" },
        { name: "Apartment Single Rooms – Half Board", link: "./apartment-single-hb" },
        { name: "Apartment Single Rooms – Bed & Breakfast", link: "./apartment-single-bb" }
    ];

    return (
        <div className='ssbb-top-section'>
            <Navbar />
            <div className="ssbb-image-container">
                <img src={backgroundImage} alt="Standard Single Room" className="ssbb-background-image" />
                <div className="ssbb-text-container">
                    <h2>Luxury Triple Room – Full Board</h2>
                    <h3>$121.00 / PER NIGHT</h3>
                </div>
            </div>

            <div className="ssbb-slider-container">
                <div className="ssbb-large-image">
                    <img src={images[currentImageIndex]} alt="Slider" className="ssbb-slider-image" />
                </div>
                <div className="ssbb-thumbnail-container">
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Thumbnail ${index + 1}`}
                            className={`ssbb-thumbnail ${index === currentImageIndex ? 'active' : ''}`}
                            onClick={() => setCurrentImageIndex(index)}
                        />
                    ))}
                </div>
            </div>

            {/* Room Details Section */}
            <div className="room-details">
                <div className="detail-item">
                    <FaUserFriends size={20} />
                    <p>Max Guests: 3</p>
                </div>
                <div className="detail-item">
                    <FaCalendarDay size={20} />
                    <p>Min. Booking Nights: 1</p>
                </div>
                <div className="detail-item">
                    <FaBed size={20} />
                    <p>Triple Bed</p>
                </div>
                <div className="detail-item">
                    <FaRulerCombined size={20} />
                    <p>266 sq</p>
                </div>
                <p>A blend of simplicity with modern comforts, rooms are modestly designed and finished. All rooms open to the cool mountain mist and dewdrops in the morning.</p>
            </div>

            {/* Room Services Section */}
            <div className="room-services">
                <h2>Room Services</h2>
                <div className="services-list">
                    <div className="service-item">
                        <FaWifi size={20} />
                        <p>Wi-Fi</p>
                    </div>
                    <div className="service-item">
                        <FaCoffee size={20} />
                        <p>Tea/Coffee Making facilities (Tea Tray)</p>
                    </div>
                    <div className="service-item">
                        <FaCocktail size={20} />
                        <p>Mini Bar</p>
                    </div>
                    <div className="service-item">
                        <FaFan size={20} />
                        <p>Ceiling Fan</p>
                    </div>
                    <div className="service-item">
                        <FaSnowflake size={20} />
                        <p>Split unit Air/conditioner</p>
                    </div>
                    <div className="service-item">
                        <FaTv size={20} />
                        <p>Cable Television</p>
                    </div>
                    <div className="service-item">
                        <FaBath size={20} />
                        <p>En-suite Bathroom with Hot & cold water</p>
                    </div>
                    <div className="service-item">
                        <FaMusic size={20} />
                        <p>Piped Music</p>
                    </div>
                </div>
            </div>

            {/* Our Other Rooms Section */}
            <div className="other-rooms">
                <h2>Our Other Rooms</h2>
                <div className="other-rooms-content">
                    <ul className="room-list">
                        {roomList.map((room, index) => (
                            <li key={index} className="room-list-item">
                                <FaDoorOpen size={16} />
                                {/* Link to the corresponding room page */}
                                <a href={room.link} className="room-link">
                                    {room.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                    <div className="other-rooms-images">
                        <img src={otherRoomImage1} alt="Room 1" />
                        <img src={otherRoomImage2} alt="Room 2" />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default LuxuryTripleRoomFB;
